import React, { useContext } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useEffectOnMounted } from 'src/hooks';
import HsidPropTypes from 'src/prop-types';
import AnalyticUtility, { handleAnalyticsTriggerError } from 'src/app/AnalyticUtility';
import AppStore from 'src/stores/AppStore';
import { QaIds } from 'src/app/QaConstants';
import { getTargetPortal } from 'src/features/PortalData';

// FIXME:
// eslint-disable-next-line import/no-cycle
import ModalUtility, { closeModal } from './Utilities/ModalUtility';
import ModalHeader from './ModalHeader/ModalHeader';
import ModalBody from './ModalBody/ModalBody';

import classes from './Modal.module.scss';

const propTypes = {
    handleCloseModal: PropTypes.func.isRequired,
    modalRef: HsidPropTypes.reactRef.isRequired,
    id: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    // DEPRECATED Use `children` for main modal body to allow for easier JSX nesting
    body: PropTypes.node,
    // Whether or not to automatically add a `LayoutContainer` to the `ModalBody`. `false` enables
    // building custom background bleeds (like gray box in Help modal)
    containContent: PropTypes.bool.isRequired,
    // Whether the modal is a lightbox style (true) or fullscreen style (false)
    lightbox: PropTypes.bool,
    children: PropTypes.node.isRequired,
    analyticsData: PropTypes.shape({
        pageName: PropTypes.string,
        uhcPageName: PropTypes.string,
    }),
};

const defaultProps = {
    containContent: true,
    body: undefined,
    lightbox: false,
    requireModalActionToClose: false,
};

// Process control block
export const keepingTrackOfAllhandleCloseModalMethods = [];
export const keepingTrackOfAllMemoryRef = [];

export const MODAL_ROOT_ID = 'modal_root';
// Variable to capture the modals holding container.
/** @type {Element} */
let modalRoot;
const getModalRoot = () => {
    // `undefined` means it hasn't been fetched yet. `.getElementById()` returns `null` for missing elements.
    // Need to also check if the modalRoot is in the current document. Without this, running multiple test cases
    // in a single file that open the modal will fail because the `modalRoot` retains the first reference while
    // the test DOM is created new with each test case. `modalRoot` will be stale on subsequent test cases in the
    // same test file
    if (modalRoot === undefined || !document.body.contains(modalRoot)) {
        modalRoot = document.getElementById(MODAL_ROOT_ID);
    }
    return modalRoot;
};

export const GenericModalIds = {
    termsOfUse: QaIds.TERMS_OF_USE_MODAL,
    privacyPolicy: QaIds.PRIVACY_POLICY_MODAL,
    consumerNotice: QaIds.CONSUMER_NOTICE_MODAL,
    learnMore: QaIds.LEARN_MORE_MODAL,
    textingTerms: QaIds.TEXTING_TERMS_MODAL,
};

/**
 * Modal will render ModalDialog as child
 */
const Modal = ({
    handleCloseModal,
    modalRef,
    lightbox,
    requireModalActionToClose,
    containContent,
    id,
    header,
    body,
    children,
    analyticsData,
}) => {
    const { portalBrand, configData } = useContext(AppStore);

    const triggerAnalyticOnLoad = () => {
        AnalyticUtility.setPageName(analyticsData.pageName);
        AnalyticUtility.setUHCPageName(analyticsData.uhcPageName);
        const onLoadAnalyticData = {
            processName: 'trackModal',
            pageName: AnalyticUtility.pageName,
            referringSite: AnalyticUtility.getReferralSite(portalBrand, getTargetPortal(configData)),
            uhcPageName: AnalyticUtility.uhcPagename,
            referringBusinessUnit: 'optum digital',
            uhcSiteSectionL1: 'hsid',
            uhcSiteSectionL2: 'modal',
            uhcSiteSectionL3: '',
            uuid: window.pageDataLayer && window.pageDataLayer.user ? window.pageDataLayer.user.uuid : undefined,
            isUhcUser: true,
        };
        AnalyticUtility.onLoadData(onLoadAnalyticData).catch(handleAnalyticsTriggerError(onLoadAnalyticData));
    };

    useEffectOnMounted(() => {
        const parentPage = {
            pageName: AnalyticUtility.pageName,
            uhcPageName: AnalyticUtility.uhcPagename,
            uhcCommondata: AnalyticUtility.uhcCommonDataForEachPage,
        };
        // This hook will push duplicate refs into the memory array in the case of nested modals as the top layer closes
        // and lower ones re-mount. Checking for existing refs should fix it. Not sure if analytic handler should be re-triggered.
        if (!keepingTrackOfAllMemoryRef.some(el => el.id === modalRef.current.id)) {
            keepingTrackOfAllhandleCloseModalMethods.push(handleCloseModal);
            keepingTrackOfAllMemoryRef.push(modalRef.current);
            ModalUtility(keepingTrackOfAllMemoryRef[keepingTrackOfAllMemoryRef.length - 1], handleCloseModal);
        }

        if (analyticsData) {
            triggerAnalyticOnLoad();
        }

        return () => {
            // when we close the modal we will go back to parent page name for submit
            AnalyticUtility.setPageName(parentPage.pageName);
            AnalyticUtility.setUHCPageName(parentPage.uhcPageName);
            AnalyticUtility.setUHCCommonDataForEachPage(parentPage.uhcCommondata);
            AnalyticUtility.loadOptumPublishPageData(undefined, { pageName: parentPage.pageName });
            AnalyticUtility.loadUhcPublishPageData(undefined, { pageName: parentPage.uhcPageName });
        };
    });

    const handleClickOutsideOfModal = event => {
        // if user clicks outside of modal then the modal will close
        if (keepingTrackOfAllMemoryRef[keepingTrackOfAllMemoryRef.length - 1]) {
            if (keepingTrackOfAllMemoryRef[keepingTrackOfAllMemoryRef.length - 1].contains(event.target)) {
                return;
            }
            closeModal(keepingTrackOfAllhandleCloseModalMethods[keepingTrackOfAllhandleCloseModalMethods.length - 1]);
        }
    };
    useEffectOnMounted(() => {
        // add when mounted
        // only add event listener when in lightbox mode
        if (lightbox && !requireModalActionToClose) {
            document.addEventListener('mousedown', handleClickOutsideOfModal);
        }
        // return function to be called when unmounted
        return () => {
            if (lightbox && !requireModalActionToClose) {
                document.removeEventListener('mousedown', handleClickOutsideOfModal);
            }
        };
    });

    const useGenericModalCss = id in GenericModalIds;

    return createPortal(
        <div className={classes.backdrop}>
            <div
                id={id}
                ref={modalRef}
                role="dialog"
                aria-modal="true"
                aria-labelledby={`${id}_modalTitle`}
                tabIndex="-1"
                className={classNames(classes.root, {
                    [classes.lightbox]: lightbox,
                })}
            >
                <ModalHeader
                    modalId={id}
                    value={header}
                    handleCloseModal={() => {
                        closeModal(handleCloseModal);
                    }}
                    lightbox={lightbox}
                    hideCloseButton={requireModalActionToClose}
                    isGeneric={useGenericModalCss}
                />
                <ModalBody modalId={id} containContent={lightbox ? false : containContent} lightbox={lightbox}>
                    {children || body}
                </ModalBody>
            </div>
        </div>,
        getModalRoot()
    );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
