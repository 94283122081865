import type { PhoneAuthType } from 'src/features/TwoFactorAuth/types';
import { countryList } from 'src/utils/phone';
import { FlowNames } from 'src/app/Constants';

import { SessionStorage } from '../../app/Storage';
import { StorageKeys } from '../../app/Storage/constants';
import type { AikyamPhoneAuthType, UpdatePhoneRequestData } from './AccountRecoveryService';
import type { AccountRecoveryData, AccountRecoveryFlowState, AikyamPhoneTwoFactorAuthSetupData } from './types';

const HsidToAikyamPhoneConfirmationType: Record<PhoneAuthType, AikyamPhoneAuthType> = {
    PhoneCall: 'CALL',
    PhoneText: 'TEXT',
};

export const getAikyamPhoneConfirmationType = (confirmationType: PhoneAuthType): AikyamPhoneAuthType =>
    HsidToAikyamPhoneConfirmationType[confirmationType];

export const getCountryCodeByIsoCode = (isoCode: string): string | undefined =>
    countryList.find(({ isoCountryCode }) => isoCode === isoCountryCode)?.countryCode;

/**
 * Convert the form data from the TFA setup forms (Phone and Security Questions) into data to send to the backend
 */
export const getTFASetupRequestData = (
    formData: {
        tfa: AikyamPhoneTwoFactorAuthSetupData;
    },
    flowState: AccountRecoveryFlowState
): UpdatePhoneRequestData => {
    // NOTE rather than refactor all of the TFA types, we know that only Phone/Text types will be used so we coerce it here
    const { tfa } = formData;
    const { referrerFlow, data } = flowState.entryFlowData;

    return {
        ...(referrerFlow === FlowNames.FORGOT_USERNAME
            ? {
                  first_name: data.firstName,
                  last_name: data.lastName,
                  dob: data.dob,
                  email: data.email,
              }
            : null),
        ...(referrerFlow === FlowNames.FORGOT_PASSWORD
            ? {
                  user_name: data.username,
              }
            : null),
        user_phones: [
            {
                iso_country_code: tfa.isoCountryCode,
                country_code: tfa.countryCode,
                phone_number: tfa.phoneNumber,
                communication_mode: getAikyamPhoneConfirmationType(tfa.confirmationType),
            },
        ],
    };
};

/**
 * Set Account Recovery flow data in Session Storage
 *
 * @param data - The account recovery data to set
 */
export const setAccountRecoveryData = (data: AccountRecoveryData): void => {
    SessionStorage.put<AccountRecoveryData>(StorageKeys.ACCOUNT_RECOVERY_DATA, data, { isEncoded: true });
};

/**
 * Get account recovery data from Session Storage
 *
 * @returns The account recovery data, or null if it doesn't exist
 */
export const getAccountRecoveryData = () =>
    SessionStorage.get<AccountRecoveryData>(StorageKeys.ACCOUNT_RECOVERY_DATA, { isEncoded: true });

/**
 * Clear account recovery data from Session Storage
 */
export const clearAccountRecoveryData = (): void => {
    SessionStorage.remove(StorageKeys.ACCOUNT_RECOVERY_DATA);
};

// Need an explicit flag here, otherwise navigating to resetpass, then to login & back will skip to step 4.
export const setResumePassword = (value: boolean): void => {
    const currentData = getAccountRecoveryData();
    SessionStorage.put(StorageKeys.ACCOUNT_RECOVERY_DATA, { ...currentData, resume: value }, { isEncoded: true });
};
