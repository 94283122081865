import React from 'react';
import type { FC, ReactNode } from 'react';

import classNames from 'classnames';

type FieldNoticeProps = {
    children: ReactNode;
    id?: string;
    className?: string;
};

const styleClasses = 'has-rds-bg-grey-lightest has-rds-radius-8 has-rds-p-16 has-text-centered';

/**
 * A component to display a static, informational notice associated with a form field
 *
 * @param props Component props
 * @returns Component instance
 */
const FieldNotice: FC<FieldNoticeProps> = ({ children, id, className }) => (
    <div id={id} className={classNames(styleClasses, className)}>
        {children}
    </div>
);

export default FieldNotice;
