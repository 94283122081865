import { FlowNames } from 'src/app/Constants';

import type { Values } from 'src/app/types';

export const AccountRecoveryFlowStep = {
    NEW_TFA_UPDATE: 'update',
    NEW_TFA_CONFIRM: 'verify',
} as const;
export type AccountRecoveryFlowStep = Values<typeof AccountRecoveryFlowStep>;

export const accountRecoveryRoutePath = `/${FlowNames.ACCOUNT_RECOVERY}/:portal/:lang?/:step?`;
