import React, { useContext } from 'react';

import AppStore from 'src/stores/AppStore';
import { Link } from 'src/UI/Link';
import { getAEMValue } from 'src/app/AEMContentUtility';
import { FlowNames } from 'src/app/Constants';
import { QaIds } from 'src/app/QaConstants';
import { LayoutContainer } from 'src/components/Containers';
import { logoutUser, logout } from 'src/app/LogoutUtility';
import { parseHsidPath } from 'src/utils/urlUtility';
import { isFlowDisabled } from 'src/components/WithFlowGuard/WithFlowGuard';
import {
    getLogoSrc,
    getSignoutUrl,
    getLogoLink,
    shouldLinkLogo,
    shouldShowSignoutLink,
    getRegisterUrl,
} from 'src/features/PortalData';
import AnalyticUtility from 'src/app/AnalyticUtility';
import { getNoAccessReferrerFlow } from 'src/flows/NoAccess/utils';
import { shouldUseAikyamURL } from 'src/features/Clients';
import { getRegisterLinkHrefProps } from 'src/app/RegisterLinkUtility';

import classes from './Header.module.scss';
import UtilityBar from '../UtilityBar/UtilityBar';
import { clearAccountRecoveryData, getAccountRecoveryData } from '../../../../flows/AccountRecovery/utils';

const getNavLinkAnalyticsData = (linkName, referringSection) => ({
    processName: 'trackLink',
    linkFriendlyName: linkName,
    uhcLinkFriendlyName: linkName,
    referringSection,
});

// TODO Management of leave prompt state should be handled by the step components
const disableLeavePrompt = () => {
    onbeforeunload = null;
};

const Header = ({ history, location }) => {
    const { AEMData, configData, portalBrand, lang } = useContext(AppStore);
    const { flowName, secure: isOnSecurePage } = parseHsidPath(location.pathname);
    const {
        DASHBOARD,
        FORGOT_USERNAME,
        REGISTER,
        LOGIN,
        FULL_STEP_UP,
        NO_ACCESS,
        FORGOT_PASSWORD,
        SETTINGS,
        ENSURE,
        EVALUATE,
        ACCOUNT_RECOVERY,
    } = FlowNames;

    const handleNavLinkClick = () => {
        disableLeavePrompt();
    };

    const isNoAccessFromEnsureOrEvaluate = () =>
        flowName === NO_ACCESS && [ENSURE, EVALUATE].includes(getNoAccessReferrerFlow());

    const renderNavLink = () => {
        switch (flowName) {
            case REGISTER:
            case FORGOT_USERNAME:
            case FORGOT_PASSWORD:
            case NO_ACCESS:
                return isNoAccessFromEnsureOrEvaluate() ? null : (
                    <Link
                        id={QaIds.HEADER_SIGNIN_ACTION}
                        hsidHref={{ flowName: LOGIN, portalBrand, lang }}
                        analyticsData={getNavLinkAnalyticsData('LOGIN', 'header nav:sign in')}
                        onClick={() => {
                            handleNavLinkClick();
                            AnalyticUtility.persistAnalyticData({ referringPageSection: 'header nav:sign in' });
                        }}
                    >
                        {getAEMValue(AEMData, 'LblLogin')}
                    </Link>
                );
            case LOGIN: {
                // If the Reg flow is disabled AND the registerUrl config is set,
                // we still want to display the Register link with the custom URL.
                if (isFlowDisabled(configData, REGISTER) && !getRegisterUrl(configData)) {
                    return null;
                }
                return (
                    <Link
                        id={QaIds.HEADER_REGISTER_ACTION}
                        analyticsData={getNavLinkAnalyticsData('REGISTER', 'header nav:register')}
                        onClick={handleNavLinkClick}
                        {...getRegisterLinkHrefProps(configData, portalBrand, lang)}
                    >
                        {getAEMValue(AEMData, 'LblRegister')}
                    </Link>
                );
            }
            case FULL_STEP_UP:
                return (
                    <Link
                        id={QaIds.HEADER_SIGNIN_ACTION}
                        hsidHref={{ flowName: LOGIN, portalBrand, lang }}
                        analyticsData={getNavLinkAnalyticsData('LOGIN', 'header nav:sign in')}
                        onClick={ev => {
                            disableLeavePrompt();
                            logoutUser(history, null, portalBrand, lang, shouldUseAikyamURL(configData));
                            ev.preventDefault();
                        }}
                    >
                        {getAEMValue(AEMData, 'LblLogin')}
                    </Link>
                );
            case DASHBOARD:
            case SETTINGS:
                return shouldShowSignoutLink(configData) ? (
                    <Link
                        id={QaIds.HEADER_SIGNOUT_ACTION}
                        href={getSignoutUrl(configData)}
                        analyticsData={getNavLinkAnalyticsData('LOGIN', 'header nav:sign out')}
                        onClick={ev => {
                            disableLeavePrompt();
                            logoutUser(
                                history,
                                null,
                                portalBrand,
                                lang,
                                shouldUseAikyamURL(configData),
                                getSignoutUrl(configData)
                            );
                            ev.preventDefault();
                        }}
                        isExternal
                    >
                        {getAEMValue(AEMData, 'LblLogout')}
                    </Link>
                ) : null;
            case ACCOUNT_RECOVERY: {
                const { referrerFlow } = getAccountRecoveryData();
                if ([FORGOT_USERNAME, FORGOT_PASSWORD].includes(referrerFlow)) {
                    return (
                        <Link
                            id={QaIds.HEADER_SIGNIN_ACTION}
                            hsidHref={{ flowName: LOGIN, portalBrand, lang }}
                            analyticsData={getNavLinkAnalyticsData('LOGIN', 'header nav:sign in')}
                            onClick={() => {
                                handleNavLinkClick();
                                clearAccountRecoveryData();
                                AnalyticUtility.persistAnalyticData({ referringPageSection: 'header nav:sign in' });
                            }}
                        >
                            {getAEMValue(AEMData, 'LblLogin')}
                        </Link>
                    );
                }
                if (referrerFlow === LOGIN) {
                    return shouldShowSignoutLink(configData) ? (
                        <Link
                            id={QaIds.HEADER_SIGNOUT_ACTION}
                            href={getSignoutUrl(configData)}
                            analyticsData={getNavLinkAnalyticsData('LOGIN', 'header nav:sign out')}
                            onClick={ev => {
                                disableLeavePrompt();
                                clearAccountRecoveryData();
                                logoutUser(
                                    history,
                                    null,
                                    portalBrand,
                                    lang,
                                    shouldUseAikyamURL(configData),
                                    getSignoutUrl(configData)
                                );
                                ev.preventDefault();
                            }}
                            isExternal
                        >
                            {getAEMValue(AEMData, 'LblLogout')}
                        </Link>
                    ) : null;
                }
                return null;
            }
            default:
                return null;
        }
    };

    const handleLogoLinkClick = ev => {
        disableLeavePrompt();
        // Only hijack the link click to perform logout for secure URLs and Evaluate flow before navigating to `siteUrl`
        // Settings page navigates to target URL without logging out.
        if ((isOnSecurePage && flowName !== SETTINGS) || flowName === EVALUATE) {
            // NOTE the click will usually originate on the `img` element which doesn't have an `href` so we
            // need to get the destination href from the parent link element.
            const destinationUrl = ev.target.matches('a') ? ev.target.href : ev.target.closest('a').href;
            logout(destinationUrl, shouldUseAikyamURL(configData));
            ev.preventDefault();
        }
    };

    return (
        <header className={classes.root} role="banner">
            <UtilityBar flowName={flowName} />
            <LayoutContainer className={classes.container}>
                {/* Left Header Module */}
                <div>
                    {shouldLinkLogo(configData, flowName) ? (
                        <Link
                            id={QaIds.HEADER_LOGO_ACTION}
                            href={getLogoLink(configData, flowName)}
                            onClick={handleLogoLinkClick}
                            variant="graphic"
                            isExternal
                        >
                            <img
                                className={classes.logoImage}
                                alt={getAEMValue(AEMData, 'LogoAltText')}
                                src={getLogoSrc(configData)}
                            />
                        </Link>
                    ) : (
                        <img
                            className={classes.logoImage}
                            alt={getAEMValue(AEMData, 'LogoAltText')}
                            src={getLogoSrc(configData)}
                        />
                    )}
                </div>

                {/* Right Header Module */}
                <div className={classes.nav}>{renderNavLink()}</div>
            </LayoutContainer>
        </header>
    );
};

export default Header;
