import { minutes } from 'src/utils/dateTimeUtility';

import type { Values } from './types';

export const HSID_BASE_URL =
    process.env.__HSID_DOMAIN_URL || (typeof window !== 'undefined' ? window.location.origin : '');

// for component current state like error, default etc.
export const ComponentState = {
    DEFAULT: 'default',
    ERROR: 'error',
    INVALID: 'invalid',
    VALID: 'valid',
    SUCCESS: 'success',
    TOUCHED: 'touched',
} as const;

export type ComponentStates = typeof ComponentState[keyof typeof ComponentState];

export const QueryParamNames = {
    OPTUMID_HEADER_ISOPTUMIDLOGIN: 'HTTP_ISOPTUMIDLOGIN',
    OPTUMID_HEADER_RBASTATUS: 'HTTP_RBASTATUS',
    TERMS_UPDATED: 'termsUpdated',
} as const;

// for response codes from backend
export const ServiceResponseCodes = {
    SUCCESS_RECORD_FOUND: '1000',
    MULTIPLE_ACCOUNT_FOUND: '1001',
    HEADLESS_USER: '1002',
    TOKEN_SENT: '1003',
    EMAIL_SENT: '1004',
    MISSING_INFO: '1005',
    CREATE_ACCOUNT: '1006',
    ADVANCE_TO_SETTING_PAGE: '1007',
    CREATE_HEADLESS: '1008',
    PROFILE_UPDATED: '1009',
    OTP_SENT: '1010',
    VERIFICATION_SUCCESS: '1011',
    SSO_COPPA: '1012',
    MEMBER_EIMP_SYNC_FAILED: '1022',
    REJECTED_MEMBER: '15000', // USER_REJECTED_DURING_RISK_ASSESSMENT

    BAD_REQUEST: '2000',
    NOT_FOUND: '2001',
    LOCKED_HSID: '2002',
    LOCKED_RBA: '2003',
    DISABLED_HSID: '2004',
    UPDATE_TERMS: '2005',
    TOKEN_INVALID: '2006',
    FAILED_TO_SEND_EMAIL: '2007',
    PARTIAL_PROFILE: '2008',
    /** @note Prefixed with "HSID11" because of conflicting numeric codes between HSID11 and HSIDEternal */
    HSID11_AUTHENTICATION_STATE_FAILED: '2013',
    /** @note HSIDEternal response code */
    VALIDATION_FAILURE: '2013',
    USERNAME_NOT_AVAILABLE: '2014',
    OTP_FAILURE: '2019',
    LOCKED_TFA: '2020',
    EXPIRED: '2021',
    ELIGIBILITY_ERROR: '2100',
    REJECTED: '2110',
    RBA_TOKEN_INVALID: '2010',
    EMAIL_PHONE_NOT_VERIFIED: '2101',
    FULL_STEP_UP: '2102',
    HSID_ALREADY_EXIST: '2103',
    MDM_OR_ELIGIBILITY_NOT_FOUND: '2105',
    PHONE_INVALID: '2108',
    SQA_INVALID: '2109',
    EXCEPTION: '3000',
} as const;
export type ServiceResponseCodes = Values<typeof ServiceResponseCodes>;

// for oidc reason codes
export const ReasonCodes = {
    NOT_FOUND: '1',
    ACCOUNT_LOCKED_LDAP: '7',
    RBA_LOCKED: '10',
    DUPLICATE_USER_DISAMBIGUATION: '20',
} as const;
export type ReasonCode = Values<typeof ReasonCodes>;

// used to configure rule name for a component
export const ValidationRuleNames = {
    REQUIRED: 'required',
    MIN_LENGTH: 'minLength',
    MAX_LENGTH: 'maxLength',
    EMAIL_FORMAT: 'emailFormat',
    CAPITAL_LETTER_VALIDATION: 'capitalLetterValidation',
    LOWERCASE_LETTER_VALIDATION: 'lowercaseLetterValidation',
    LIST_NUMBER_VALIDATION: 'listNumberValidation',
    ATLEAST_LETTER: 'atleastLetter',
    MULTIPLE_SPACES: 'multipleSpaces',
    SPECIAL_CHARACTERS: 'specialCharacters',
    SPECIAL_CHARACTERS_NAME_VALIDATION: 'specialCharactersNameValidation',
    SPECIAL_CHARACTERS_SQA: 'specialCharactersSqa',
    ALPHANUMERIC: 'alphaNumeric',
    NO_SPACE: 'noSpace',
    DOUBLE_SPACE: 'doubleSpace',
    CUSTOM_PSWD_CASE: 'customPswdCase',
    PATTERN: 'pattern',
} as const;

// used for types of components
export const ComponentType = {
    TIER_1: 'tier1',
    TIER_2: 'tier2',
    TIER_3: 'tier3',
    TIER_4: 'tier4',
    EMAIL: 'email',
} as const;

// for Summary Error
export const SummaryErrorType = {
    PLAIN_TEXT: 'plainText',
    LIST: 'list',
    LIST_WITH_PLAIN_TEXT: 'listElementWithPlainText',
} as const;

export type SummaryErrorTypes = typeof SummaryErrorType[keyof typeof SummaryErrorType];

// for Flownames distinguishing which flows certain routes came from
export const FlowNames = {
    /** Account Security Recovery flow */
    ACCOUNT_RECOVERY: 'recovery',
    AUTH: 'auth',
    DASHBOARD: 'dashboard',
    ELIGIBILITY_ERROR: 'eligibilityError',
    ENSURE: 'ensure',
    EVALUATE: 'evaluate',
    FORGOT_PASSWORD: 'password',
    FORGOT_USERNAME: 'username',
    FULL_STEP_UP: 'fullstepup',
    LOGIN: 'login',
    NO_ACCESS: 'noaccess',
    BLOCKED: 'blocked',
    REGISTER: 'register',
    SECURE: 'secure',
    SETTINGS: 'settings',
    SSO: 'sso',
    VERIFY_LOGIN: 'verifylogin',
    VERIFY_ACCOUNT: 'verifyaccount',
} as const;
export type FlowName = typeof FlowNames[keyof typeof FlowNames];

export const Tier4ErrorMsgState = {
    DEFAULT: 'default',
    FAILED: 'failed',
    PASSED: 'passed',
} as const;

export const BASE_ROUTE = '/rt' as const;
export const SITEMINDER_PARAM_PREFIX = '$SM$' as const;

// for account recovery options
/** @deprecated */
export const ConfirmationTypes = {
    PHONE_CALL: 'PhoneCall',
    PHONE_TEXT: 'PhoneText',
    SECURITY_QUESTIONS: 'Security',
    EMAIL: 'Email',
} as const;

// cross storage should stay in the same domain as HSID deployement
export const CROSS_STORAGE_URL = `${process.env.__CROSS_STORAGE_ORIGIN || HSID_BASE_URL}/protected/crossStorageHub`;

export const CrossStorage = {
    UNAMEKEY: 'AbKOxYoXtWLfjQ3EnKx8IzioU1rrTyGF',
    FNAMEKEY: 'xR6kx85V7nGCIQIw7JeZ3qyk0ak0o5Q8',
    LNAMEKEY: 'DbUawHXlWEkS9SipUGMQdEnvGJ1Lh40Y',
    SSOKEY: 'B8akjPew3WerNK0jsdTq0SDakso1UkQd',
    SSOCACHEKEY: 'l9d300XU0PPG0ZLfjSPiSOqdfQifIItW',
} as const;

export const RegistrationStepNames = {
    STEP1: 'personal-info',
    STEP2: 'credentials',
    STEP3: 'recovery-option',
    STEP4: 'recovery-verify',
    STEP5: 'review',
} as const;

export const VerifyLoginStepNames = {
    STEP1: 'verify-all',
    STEP1_2: 'verify-email',
    STEP2: 'confirm',
} as const;

export const ResetPasswordStepNames = {
    STEP1: 'username',
    STEP2: 'verify-option',
    STEP3: 'confirm',
    STEP4: 'reset',
} as const;

export const ForgotUsernameStepNames = {
    STEP1: 'identity',
    STEP2: 'verify-option',
    STEP3: 'confirm',
} as const;

export const DobFormat = 'YYYY-MM-DD' as const;

export const ACTION = {
    SIGNIN: 'SIGNIN',
    REGISTER: 'REGISTER',
    USERNAME: 'USERNAME',
    PASSWORD: 'PASSWORD',
} as const;
export type ACTION = Values<typeof ACTION>;

export const AemDataAttributes = {
    GOTO_FLOW: 'data-goto-flow',
    SHOW_MODAL: 'data-show-modal',
} as const;

export const MimeType = {
    Json: 'application/json',
} as const;

export const Headers = {
    Json: {
        Accept: MimeType.Json,
        'Content-Type': MimeType.Json,
    },
} as const;

/**
 * All of the reserved URL params that activate specific functionality in HSID UI
 *
 * With the addition of portal config settings that allow portals to define URL params to handle specially, this object
 * will serve as a single source of truth for URL param names that cannot be used by portals. The following config
 * settings are the ones where custom URL params can be defined:
 * @see PortalConfigData.feature.extraFlowDataFromUrl
 * @see PortalConfigData.feature.persistUrlParamsToPortal
 */
export const ReservedUrlParams = {
    FAST_TRACK_REGISTRATION_TOKEN: 'rToken',
    ASSISTED_REGISTRATION_TOKEN_NAME: 'openToken',
} as const;

// Minutes after which session timeout popup would be displayed
export const TimeoutDuration = minutes(28.5);
export const SQATimeoutDuration = minutes(9);

export const isTMXAikyamRegEnabled = process.env.__TMX_AIKYAM_REG_ENABLED === 'true'; // TODO FUTURE - this is always true now; should remove
