import isEmptyObject from 'src/utils/isEmptyObject';
import { FlowNames } from 'src/app/Constants';

import type { FlowName } from 'src/app/Constants';

import type { PortalConfigData } from './PortalConfigData';

/**
 * PortalConfigData selectors
 *
 * These should all take the root config object to get the relevant data
 */
export const getLogoSrc = (config: PortalConfigData): string => {
    const { contentHost, brandUrl } = config;
    return `${contentHost}${brandUrl}`;
};

export const getHsidBrandLogoSrc = (config: PortalConfigData): string =>
    `${config.contentHost}/content/dam/hsid/HSID_Logo.png`;

export const getSignoutUrl = (config: PortalConfigData): string => config.localeSpecific.signoutUrl;

export const getSiteUrl = (config: PortalConfigData): string => config.localeSpecific.siteUrl;

export const getSkipUrl = (config: PortalConfigData): string => config.localeSpecific.skipUrl ?? '';

export const getSettingsBackUrl = (config: PortalConfigData): string | null =>
    config.localeSpecific.settingsBackUrl ?? null;

export const getRegisterUrl = (config: PortalConfigData): string | null => config.localeSpecific.registerUrl ?? null;

export const getTargetUrl = (config: PortalConfigData): string => config.localeSpecific.targetUrl;

export const getTargetPortal = (config: PortalConfigData): string => config.portalName.toUpperCase();

export const getPrivacyPolicyUrl = (config: PortalConfigData): string => config.localeSpecific.privacyPolicyUrl ?? '';

export const getTermsOfUseUrl = (config: PortalConfigData): string => config.localeSpecific.termsOfUseUrl ?? '';

export const getContentHost = (config: PortalConfigData): string => config.contentHost;

export const getEligibility = (config: PortalConfigData): string => config.feature.eligibility;

export const isNativeApp = (config: PortalConfigData): boolean => config.feature.isNativeApp;

export const shouldLinkLogo = (config: PortalConfigData, currentFlowName: FlowName): boolean => {
    if (isNativeApp(config)) {
        return false;
    }

    // Target URL is required and Settings page and Ensure flow use it to link the logo so we can just assume `true`
    if (([FlowNames.SETTINGS, FlowNames.ENSURE] as FlowName[]).includes(currentFlowName)) {
        return true;
    }

    const siteUrl = getSiteUrl(config);
    return siteUrl != null && siteUrl !== '';
};

export const getLogoLink = (config: PortalConfigData, currentFlowName: FlowName): string =>
    ([FlowNames.SETTINGS, FlowNames.ENSURE] as FlowName[]).includes(currentFlowName)
        ? getTargetUrl(config)
        : getSiteUrl(config) ?? '';

export const shouldShowSignoutLink = (config: PortalConfigData): boolean =>
    !!getSignoutUrl(config) && !isNativeApp(config);

export const shouldShowSettingsBackLink = (config: PortalConfigData): boolean =>
    !!getSettingsBackUrl(config) && !isNativeApp(config);

export const isUiDisabled = (
    config: PortalConfigData,
    uiName: keyof PortalConfigData['feature']['disabledUi']
): boolean => config.feature.disabledUi[uiName] === true;

export const getIdentificationTypes = (config: PortalConfigData): PortalConfigData['uiIdentificationTypes'] =>
    config.uiIdentificationTypes;

export const isCoppaEnabled = (config: PortalConfigData): boolean => config.feature.isCoppaEnabled;

export const isAccountRecoveryFlowEnabled = (config: PortalConfigData): boolean =>
    config.feature.isAccountRecoveryFlowEnabled;

/** Assisted Registration selectors */
export const getAssistedRegistrationConfig = (
    config: PortalConfigData
): PortalConfigData['feature']['assistedRegistration'] => config.feature.assistedRegistration;

export const getClickToCall = (config: PortalConfigData): PortalConfigData['feature']['clickToCall'] =>
    config.feature.clickToCall;

export const getExtraFlowDataFromUrl = (
    config: PortalConfigData
): PortalConfigData['feature']['extraFlowDataFromUrl'] => config.feature.extraFlowDataFromUrl;

export const getPersistUrlParamsToPortal = (
    config: PortalConfigData
): PortalConfigData['feature']['persistUrlParamsToPortal'] => config.feature.persistUrlParamsToPortal;

export const isAssistedRegistrationEnabled = (config: PortalConfigData): boolean =>
    !isEmptyObject(getAssistedRegistrationConfig(config));

export const shouldVerifyAssistedRegistrationToken = (config: PortalConfigData): boolean => {
    if (!isAssistedRegistrationEnabled(config)) {
        return false;
    }

    const { enableTokenVerification } = getAssistedRegistrationConfig(config);
    return enableTokenVerification === true;
};

export const canManuallySkipAssistedRegistration = (config: PortalConfigData): boolean => {
    if (!isAssistedRegistrationEnabled(config)) {
        return false;
    }

    // TODO this should also check for skipUrl since that's necessary to be able to skip
    return getAssistedRegistrationConfig(config).enableSkipRegistration === 'manual';
};

export const shouldAutomaticallySkipAssistedRegistration = (config: PortalConfigData): boolean => {
    if (!isAssistedRegistrationEnabled(config)) {
        return false;
    }

    // TODO this should also check for skipUrl since that's necessary to be able to skip
    return getAssistedRegistrationConfig(config).enableSkipRegistration === 'auto';
};
/** End Assisted Registration selectors */

export const isQualtricsEnabled = (config: PortalConfigData): boolean => config.feature.qualtrics.isEnabled ?? false;

export const getQualtricsId = (config: PortalConfigData): string => config.feature.qualtrics.qualtricsId ?? '';

export const isWebChatEnabled = (config: PortalConfigData): boolean | 'caip' | 'live' | '' => {
    const { feature } = config;

    if (feature.isWebChatEnabled === true || feature.isWebChatEnabled === 'caip') return 'caip';
    if (feature.isWebChatEnabled === false || feature.isWebChatEnabled === '') return '';
    return feature.isWebChatEnabled;
};

export const getTheme = (config: PortalConfigData): PortalConfigData['theme'] => config.theme;

export const getSupportedLocales = (config: PortalConfigData): PortalConfigData['supportedLocales'] =>
    config.supportedLocales;

// While the config flag is opt-in show to start with, the long term will have the HSID brand logo hidden as the
// exception so we define this selector with that long term goal in mind so that in the future, we only need to change
// the config setting here and in the type definitions and not change this function every it is used.
export const shouldHideHsidBrandLogo = (config: PortalConfigData): boolean => config.feature.shouldHideHsidBrandLogo;

export const isObaEnabled = (config: PortalConfigData): boolean => config.feature.isOBAEnabled;

export const getTmxDomainUrl = (config: PortalConfigData): string => config.tmxDomainUrl;

export const shouldUseMultiTierRegistrationFlow = (config: PortalConfigData): boolean =>
    config.feature.enableMultiTierRegistrationFlow;

export const shouldUseHsidEternalEndpoints = (config: PortalConfigData): boolean => config.feature.shouldUseHsidEternal;

export const isRBAReassessmentEnabled = (config: PortalConfigData): boolean => config.feature.isRBAReassessmentEnabled;

export const isAikyamMFAEnabled = (config: PortalConfigData): boolean => config.feature.isAikyamMFAEnabled;

export const isAikyamLoginEnabled = (config: PortalConfigData): boolean => config.feature.isAikyamLoginEnabled;

export const shouldCallLegacyPreAuth = (config: PortalConfigData): boolean =>
    config.feature.shouldCallLegacyPreAuth || process.env.__CALL_LEGACY_LOGIN_PREAUTH === 'true';
